import * as React from 'react';
import { useTheme, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

import CssBaseline from '@mui/material/CssBaseline';
import { navigate } from "gatsby"
import Ad from '../components/Ad'
import Grid from '@mui/material/Grid';
import Menu from '../components/Menu'
import useMediaQuery from '@mui/material/useMediaQuery';
import { useLocation } from '@reach/router';

const ContentLayout = styled(Grid)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.up("lg")]: {
    flexDirection: 'row',
  }
}));

const MenuLayout= styled(Grid)(({ theme }) => ({
  padding: "0 32px 0 32px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  [theme.breakpoints.up("lg")]: {
    justifyContent: "start",
    alignItems: "end"
  }
}));

function parseQuery(queryString) {
  var query = {};
  var pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
  for (var i = 0; i < pairs.length; i++) {
    var pair = pairs[i].split('=');
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }
  return query;
}


export default function PersistentDrawerRight({ fullWidth, content, children }) {


  const location = useLocation();
  const params = parseQuery(location.search)
  const hideMenu = params.hideMenu;
  const hideLogo = params.hideLogo;
  const theme = useTheme();
  const isSM = useMediaQuery(theme.breakpoints.up('sm'));


  const [anchorEl, setAnchorEl] = React.useState(null);


  const Logo = ({ xs, lg }) => {
    return <Box display="flex" justifyContent="end">
      <img alt="" onClick={() => navigate('/')} src="/images/logo2.png" style={{ width: "256px", cursor: 'pointer' }} />
    </Box>
  };

  const openMenu = Boolean(anchorEl);
  const id = openMenu ? 'simple-popover' : undefined;

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Box display="flex" marginTop="16px" flexDirection="column" width="100%" alignItems="center">
          <ContentLayout>
            {
              !fullWidth && <MenuLayout item xs={12} lg={4}>
                <Logo />
                <Menu />
              </MenuLayout>
            }
            <Grid item xs={12} lg={fullWidth ? 12 : 8}>
              {
                children
              }
            </Grid>
          </ContentLayout>
          <Box minWidth="250px" minHeight="50px">
            <Ad />
          </Box>
          <Box display="flex" padding="16px" flexDirection="column" style={{ backgroundColor: 'black', color: "white" }} >
            <h3>Do your own Research</h3>

            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
              Under no circumstances OpenFengShui be held responsible or liable in any way for any claims, damages, losses, expenses, costs or liabilities whatsoever (including, without limitation, any direct or indirect damages for loss of profits, business interruption or loss of information) resulting or arising directly or indirectly from your use of or inability to use this website or any websites linked to it, or from your reliance on the information and material on this website, even if the OpenFengShui has been advised of the possibility of such damages in advance.
              <Box display="flex" flexDirection="row" gap="8px">
                <a style={{ textDecoration: 'none', color: "blue" }} href="/term">Privacy</a>
                <a style={{ textDecoration: 'none', color: "blue" }} href="/term">Term and Condition</a>
              </Box>

            </Box>
          </Box>
        </Box >

      </Box >

    </>
  );
}

