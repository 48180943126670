/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from "react"
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import Layout from "./src/components/layout";
import { AuthProvider } from './src/contexts/AppContext'

const theme = createTheme({
    typography: {
        allVariants: {
            fontFamily: '"Segoe UI"',
        },
    },
});

export const wrapPageElement = ({ element }) => <ThemeProvider theme={theme}><AuthProvider>
    {element}

</AuthProvider>
</ThemeProvider>

