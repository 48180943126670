// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ask-js": () => import("./../../../src/pages/ask.js" /* webpackChunkName: "component---src-pages-ask-js" */),
  "component---src-pages-change-password-js": () => import("./../../../src/pages/changePassword.js" /* webpackChunkName: "component---src-pages-change-password-js" */),
  "component---src-pages-find-js": () => import("./../../../src/pages/find.js" /* webpackChunkName: "component---src-pages-find-js" */),
  "component---src-pages-find-mobile-number-js": () => import("./../../../src/pages/findMobileNumber.js" /* webpackChunkName: "component---src-pages-find-mobile-number-js" */),
  "component---src-pages-forgot-password-js": () => import("./../../../src/pages/forgotPassword.js" /* webpackChunkName: "component---src-pages-forgot-password-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-manage-js": () => import("./../../../src/pages/manage.js" /* webpackChunkName: "component---src-pages-manage-js" */),
  "component---src-pages-mobile-js": () => import("./../../../src/pages/mobile.js" /* webpackChunkName: "component---src-pages-mobile-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-plotter-js": () => import("./../../../src/pages/plotter.js" /* webpackChunkName: "component---src-pages-plotter-js" */),
  "component---src-pages-plotter-v-2-js": () => import("./../../../src/pages/plotter-v2.js" /* webpackChunkName: "component---src-pages-plotter-v-2-js" */),
  "component---src-pages-post-js": () => import("./../../../src/pages/post.js" /* webpackChunkName: "component---src-pages-post-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-product-js": () => import("./../../../src/pages/product.js" /* webpackChunkName: "component---src-pages-product-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-term-js": () => import("./../../../src/pages/term.js" /* webpackChunkName: "component---src-pages-term-js" */)
}

