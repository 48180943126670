import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { Grass, GpsFixed, Password, RepeatOne, Login, SixtyFps, Assignment, Cloud, Inventory2, Filter9, VerifiedUser, SendToMobile, ManageSearch, Gavel, WhatsApp, Home } from '@mui/icons-material';
import { navigate } from "gatsby"
import Ad from '../components/Ad'
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import useAuth from "../hooks/userAuth";

import { useState, useEffect } from 'react'

export default function Menu({ children }) {
    const { isAuth, getEnv, logout, user } = useAuth()
    const authenticated = isAuth()
    return (
        <>
            <MenuList>

                <MenuItem onClick={() => navigate('/')}>
                    <ListItemIcon>
                        <Home fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Meta AI</ListItemText>
                </MenuItem>
                {

                    "" && <>
                        <MenuItem onClick={() => navigate('/ask')}>
                            <ListItemIcon>
                                <Filter9 fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>五行数字 Five Element Number</ListItemText>
                        </MenuItem>

                        <MenuItem onClick={() => navigate('/mobile')}>
                            <ListItemIcon>
                                <SendToMobile fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>数字能量 Number Energy</ListItemText>
                        </MenuItem>
                    </>
                }
                {
                    <MenuItem onClick={() => navigate('/findMobileNumber')}>
                        <ListItemIcon>
                            <RepeatOne fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Mobile Number Finding</ListItemText>
                    </MenuItem>
                }
                {
                    <MenuItem onClick={() => window.location.assign('/plotter-v2')}>
                        <ListItemIcon>
                            <RepeatOne fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Feng Shui Map Plotter</ListItemText>
                    </MenuItem>
                }
                {
                    <MenuItem onClick={() => window.location.assign('/plotter')}>
                        <ListItemIcon>
                            <RepeatOne fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Feng Shui Image Plotter</ListItemText>
                    </MenuItem>
                }
                {
                    !authenticated && <MenuItem onClick={() => navigate('/login')}>
                        <ListItemIcon>
                            <Login fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Login</ListItemText>
                    </MenuItem>
                }
                {
                    authenticated && <>
                        <MenuItem onClick={() => navigate('/changePassword')}>
                            <ListItemIcon>
                                <Password fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Update Password</ListItemText>
                        </MenuItem>
                        <MenuItem onClick={() => logout()}>
                            <ListItemIcon>
                                <Login fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Logout</ListItemText>
                        </MenuItem>

                    </>
                }

            </MenuList>
        </>
    );
}

